import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.join.js";
import axios from 'axios';
import Keyboard from 'simple-keyboard';
import dayjs from 'dayjs';
import 'simple-keyboard/build/css/index.css';
import _ from 'lodash';
export default {
  data: function data() {
    return {
      id: this.$route.params.id,
      word_length: 0,
      word_number: null,
      attempts: [],
      responses: [],
      current: [],
      valid_chars: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
      start: null,
      end: null,
      show_game: false,
      countdown: 0,
      sending: false,
      // current sending attempt,
      keyboard: null
    };
  },
  props: {},
  mounted: function mounted() {
    this.initKeyboard();
    this.loadGameInfo();
    var $that = this;
    document.addEventListener('keyup', function (e) {
      $that.onDocumentKeyUp(e);
    });
  },
  watch: {
    countdown: function countdown(val) {
      if (val <= 0) {
        this.$toast.info('Game is over!', {
          duration: 3000,
          position: 'top'
        });
        var $that = this;
        setTimeout(function () {
          var prevRoom = window.localStorage.getItem('prevRoom');
          if (prevRoom != null) $that.$router.replace(prevRoom);
        }, 3000); // go back to room
      }
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      if (from != null && from.fullPath != '/') {
        window.localStorage.setItem('prevRoom', from.fullPath);
      }
    });
  },
  unmounted: function unmounted() {
    if (this.keyboard != null) this.keyboard.destroy();
    this.keyboard = null;
  },
  methods: {
    loadGameInfo: function loadGameInfo() {
      var $that = this;
      axios.get('game/' + this.id, {
        withCredentials: true,
        validateStatus: false
      }).then(function (resp) {
        if (resp.status == 200) {
          $that.start = resp.data.start;
          $that.end = resp.data.end; // set current progress if mid-game

          if (resp.data.progress != null) {
            $that.word_length = resp.data.progress.word_length;
            $that.word_number = resp.data.progress.word_number;
            $that.attempts = [];
            $that.responses = [];

            _.each(resp.data.progress.attempts, function (a) {
              $that.attempts.push(a.attempt);
              $that.responses.push(a.response);
            });
          }

          $that.$refs.body.focus();
          $that.refreshTimer();
          $that.setCountdown();
        } else {
          $that.$toast.error('Could not find game or game completed.', {
            position: 'bottom',
            duration: 7000
          });
        }
      });
    },
    initKeyboard: function initKeyboard() {
      var $that = this;
      this.keyboard = new Keyboard({
        layout: {
          'default': ['Q W E R T Y U I O P', 'A S D F G H J K L', 'Z X C V B N M', '{bksp} {enter}']
        },
        display: {
          '{enter}': 'SEND/ENTER',
          '{bksp}': 'DEL'
        },
        onKeyPress: function onKeyPress(button) {
          return _onKeyPress(button);
        }
      });

      function _onKeyPress(button) {
        var key;
        if (button == '{enter}') key = 'Enter';else if (button == '{bksp}') key = 'Backspace';else key = button;
        $that.onKeyUp({
          key: key
        });
      }
    },
    onKeyUp: function onKeyUp(e) {
      if (e.key != 'Backspace' && e.key != 'Enter') {
        if (this.current.length < this.word_length) {
          if (this.valid_chars.indexOf(e.key.toLowerCase()) > -1) {
            this.current.push(e.key.toUpperCase());
          }
        }
      } else if (e.key == 'Backspace') {
        if (this.current.length != 0) {
          this.current.pop();
        }
      } else if (e.key == 'Enter') {
        if (this.current.length == this.word_length) {
          if (!this.sending) // not currently sending attempt
            this.sendAttempt();
        }
      }
    },
    onDocumentKeyUp: function onDocumentKeyUp(e) {
      if (e.which == 8) // backspace
        e.preventDefault();
    },
    setCountdown: function setCountdown() {
      var millis = dayjs(this.start).valueOf() - dayjs().valueOf();
      if (millis <= 0) this.show_game = true;else {
        this.$toast.info(Math.floor(millis / 1000) + '', {
          duration: 450,
          queue: true,
          position: 'top'
        });
        setTimeout(this.setCountdown, 1000);
      }
    },
    refreshTimer: function refreshTimer() {
      var $that = this;
      this.countdown = dayjs(this.end).valueOf() - dayjs().valueOf();
      if (this.countdown >= 0) setTimeout(function () {
        $that.refreshTimer();
      }, 1000);else this.countdown = 0;
    },
    formatMillis: function formatMillis(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = (millis % 60000 / 1000).toFixed(0);
      return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    },
    sendAttempt: function sendAttempt() {
      var $that = this;
      this.sending = true;
      var word = this.current.join('');
      axios.post('game/attempt', {
        word: word
      }, {
        withCredentials: true
      }).then(function (resp) {
        if (resp.status == 200) {
          $that.sending = false;

          if (resp.data.error) {
            $that.$toast.error(resp.data.message, {
              duration: 2000,
              position: 'top'
            });
            return;
          }

          $that.attempts = [];
          $that.responses = [];
          $that.current = [];

          if (resp.data.success) {
            // guessed the word correctly!
            $that.word_length = resp.data.word_length;
            $that.word_number = resp.data.word_number;
            $that.$toast.success('You guessed the correct word: ' + word + '!', {
              duration: 3000,
              position: 'top'
            });
          } else if (resp.data.skip_word) {
            $that.word_length = resp.data.word_length;
            $that.word_number = resp.data.word_number;
            $that.$toast.success('Max attempts reached. Skipping word...', {
              duration: 3000,
              position: 'top'
            });
          } else {
            // populate latest results
            _.each(resp.data.attempts, function (a) {
              $that.attempts.push(a.attempt);
              $that.responses.push(a.response);
            });
          }
        }
      });
    }
  }
};